import React, { Fragment } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Hero from '@components/theme/Hero';
import Footer from '@components/Footer';
import { has } from 'lodash';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';
import { createUtmQuery } from '@lib/searchQuery';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import CaseStudyHero from '@components/theme/CaseStudyHero';
import SharePage from '@components/theme/SharePage';
import ReadingArticle from '@components/theme/ReadingArticle';
import NavBar from '@components/theme/NavBar';

const utmMedium = 'jobs';

export default function VideosTemplate({
  data: {
    job
  },
  pageContext: { language, refs, layouts, configuration, meta }
}) {
  return (
    <Fragment>
      <Hero title={job.job.name} subtitle={job.job.location}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <ReadingArticle body={job.job.description}>
      </ReadingArticle>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($id: String) {
    job: wpJob(id: {eq: $id}) {
      seo {
        focuskw
      }
      properties {
        slug
        title
      }
      job {
        name
        description
        location
        team
      }
    }
  }
`;